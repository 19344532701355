import React from "react"
import PageLayout from "../components/PageLayout"


export default function Contact() {
  return (<div>
    <PageLayout>
      
      <iframe className="contactform" width="640px" height= "480px" src= "https://forms.office.com/Pages/ResponsePage.aspx?id=Qk0_6F8ZL0WsOx1Ha-aql59xE9dj_rpOkkfwdJBbb0dUMTUwTklLMDRRNlFMMjlBRkdJRzcyTkZBWi4u&embed=true" frameborder= "0" marginwidth= "0" marginheight= "0"  allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>

    </PageLayout>
  </div>)
}
